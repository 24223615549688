.btn {
  margin-right: 2px;
}
.floorplanStyle {
  position: absolute;
  line-height: 100px;
  text-align: center;
  background: #d9d5dc;
  color: rgb(143, 136, 136);
  border: 1px solid #333;
  box-sizing: border-box;
  opacity: 0.5;
}
.selected {
  z-index: 1000 !important;
}
.opacity-half {
  opacity: 0.9;
}
.cursor-pointer {
  cursor: pointer;
}
.focused {
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.hidden {
  visibility: hidden;
}
.maxWidth-800 {
  max-width: 800px;
}
.maxWidth-1000 {
  max-width: 1000px;
}
